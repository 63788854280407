import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import { Form, Formik, input } from "react"
import OurFeatures from "../../components/Index/OurFeatures"
import wlogo from "../../assets/images/wlogo.svg"

const FacebookFormSubmitted = () => {
  return (
    <Layout>
      <SEO title="Oxygen | Home Loans" />

      <div className="page-partner-landing thank-you">
        <div className="ld-content-wrapper">
          <div className="ld-content">
            <div className="text-content">
              <h2 className="mt-1">Thank you!</h2>
              <div className="para">Your guide is on the way.</div>

              <div className="para">
                Last month our customers{" "}
                <span className="green-text"> saved an average of $243 </span>
                <br /> on their homeloan payments. You can too!
              </div>

              <div className="whitelogofb">
                <a href="/">
                  {" "}
                  <img src={wlogo} alt="logo" />{" "}
                </a>
              </div>

              {/* <h2>Thank you!</h2>
                <div>Your guid is on the way.</div>
              <div className="para">Last month, our coustomers saved an average of $243 on their home loan payments. You can too!</div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FacebookFormSubmitted
